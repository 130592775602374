import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import posed from "react-pose"
import Layout from "../components/layout"
import styles from "../styles/player.module.css"
import closeSVG from "../images/close.svg"

const Box = posed.div({
  closed: { y: "300px" },
  open: { y: "0px", transition: { duration: 600 } },
})

const Back = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.2,
  },
  press: {
    scale: 1.1,
  },
})

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Gaston Hummel</title>
      </Helmet>
      <Box className="container" initialPose="closed" pose="open">
        <div className="headerOther">
          <Back>
            <Link to="/speeches">
              <img src={closeSVG} alt="back" height="32" width="32" />
            </Link>
          </Back>
          <h2 className="center nomargin">
            {post.frontmatter.title} | {post.frontmatter.date}
          </h2>
        </div>
        <div className={styles.player}>
          <iframe
            width="100%"
            height="100%"
            title="Video Player"
            src={`https://www.youtube.com/embed/${post.frontmatter.video}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        type
        date(formatString: "DD MMM YYYY")
        video
      }
    }
  }
`
